<template>
    <ValidationObserver ref="observer" v-slot="{ invalid }" class="contents" tag="div">
        <h1 class="pagetitle">お客様ログイン</h1>
        <ValidationProvider name="メールアドレス" rules="required" tag="div">
            <input v-model="login.mail_address" class="l-mt4 l-mb7" type="text" placeholder="メールアドレス">
        </ValidationProvider>
        <ValidationProvider name="パスワード" rules="required" tag="div">
            <input v-model="login.password" class="l-mb8" type="password" placeholder="パスワード">
        </ValidationProvider>
        <div class="l-mb8 is-center">
            <button v-if="!loader" :class="X_classValidatedButton" type="button" :disabled="$_setValidatedDisabled(invalid)" @click="handleLogIn()">ログイン</button>
            <Loader :loader="loader" />
        </div>
        <div v-if="!loader" class="is-center">
            <button class="button--text-link" type="button" @click="$_goBack()">お客様情報入力へ戻る</button>
        </div>
    </ValidationObserver>
</template>

<script>
    "use strict";

    import Loader from "@/components/Loader.vue";
    import common from "@/mixins/common";
    import confirmSave from "@/mixins/confirmSave";

    export default {
        "components": {
            Loader,
        },
        "mixins": [
            common,
            confirmSave,
        ],
        "props": {
            "contents": {
                "required": true,
                "type": Object,
            },
        },
        data() {
            return {
                "loader": false,
                "login": {
                    "mail_address": "",
                    "password": "",
                },
            };
        },
        created() {
            if (!this.contents.input.order.length) {
                this.$router.push({ "name": "shop_list" });
            }
        },
        "methods": {
            handleLogIn() {
                this.loader = true;
                // ログイン
                common.$_axios.post(
                    `${common.$_configs.apiUrl}/login`,
                    this.login
                ).then(response => {
                    localStorage.setItem("token", response.data.access_token);
                    this.contents.loginData = response.data;
                    this.contents.flagLogin = true;
                    this.$router.push({ "name": "input" });
                }).catch(() => {
                    this.loader = false;
                    alert("ログインに失敗しました。");
                });
            },
        },
    };
</script>
